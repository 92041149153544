import React, { FunctionComponent } from "react";
import { WindowLocation } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./FadeTransitionRouter.scss";

const transitionDuration = 300;

const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  entered: {
    transition: `opacity ${transitionDuration / 2}ms ease-in`,
    opacity: 1,
    willChange: 'opacity',
  },
  exiting: {
    transition: `opacity ${transitionDuration / 2}ms ease-in`,
    opacity: 0,
    willChange: 'opacity',
  },
};

interface Props {
  children: ReactNode;
  location: WindowLocation<{ disableTransition?: boolean }>;
}

const FadeTransitionRouter: FunctionComponent<Props> = props => {
  const { children, location } = props;

  if (location.state?.disableTransition) {
    return <>{children}</>;
  }

  return (
    <TransitionGroup className="TransitionGroup">
      <CSSTransition
        key={location.key}
        timeout={parseInt(transitionDuration.toString(), 10)}
      >
        {status => (
          <div
            style={{
              ...getTransitionStyles[status],
            }}
          >
            {children}
          </div>
        )}
      </CSSTransition>
    </TransitionGroup>
  );
};

export default FadeTransitionRouter;
