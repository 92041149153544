import React, { useState, useEffect, useRef, useCallback } from "react";
import { datadogRum } from "@datadog/browser-rum";
import TagManager from "react-gtm-module";
import classNames from "classnames";
import { isClient } from "../../utils/env";
import "./CookiesPopup.scss";

const CookiesPopup = () => {
  const [isOpenedSettings, setIsOpenedSettings] = useState(false);
  const [isShowBlock, setIsShowBlock] = useState(false);
  const [cookieAnalytics, setCookieAnalytics] = useState(true);
  const analyticsCheckbox = useRef(null);

  const initAllAnalytics = useCallback(() => {
    datadogRum.init({
      applicationId: process.env.GATSBY_DATADOG_APP_ID || '',
      clientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN || '',
      site: "datadoghq.com",
      service: "casa",
      env: "prod",
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: "allow",
      silentMultipleInit: true
    });
    datadogRum.startSessionReplayRecording();

    const tagManagerArgs = { gtmId: process.env.GATSBY_GTM };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const handleToggleSettings = () => {
    setIsOpenedSettings(!isOpenedSettings);
  };

  const setCookies = useCallback(() => {
    const lStorage = isClient ? window.localStorage : null;
    const settings = {
      analytics: `${cookieAnalytics}`
    };
    if (lStorage) {
      lStorage.setItem("gdpr-casa", JSON.stringify(settings));
    }
    setIsShowBlock(false);

    if (cookieAnalytics) {
      initAllAnalytics();
    }
  }, [initAllAnalytics, cookieAnalytics]);

  const handleCheckboxClick = () => {
		setCookieAnalytics(analyticsCheckbox.current.checked);
  };

  const handleSaveSettings = () => {
    setCookies();
  };

  const setAllCookies = () => {
    setCookieAnalytics(true);
    setCookies();
  };

  useEffect(() => {
    const lStorage = isClient ? window.localStorage.getItem("gdpr-casa") : null;
    if (lStorage) {
      setIsShowBlock(false);
      const storageData = JSON.parse(lStorage);
      if (storageData.analytics === "true") {
        initAllAnalytics();
      }
    } else {
      setIsShowBlock(true);
    }
  }, [initAllAnalytics]);

  return (
    <div
      className={classNames(
        "cookies-popup",
        isShowBlock && "cookies-popup_show"
      )}
    >
      {!isOpenedSettings && (
        <div className="cookies-popup__inner">
          <div className="cookies-popup__content">
            <div className="cookies-popup__title">
              Our website uses cookies
            </div>
            <div className="cookies-popup__text">
              Thank you for using CASA. The information that’s shared with us
              helps to provide a great experience for you.
            </div>
          </div>
          <div className="cookies-popup__buttons">
            <button
              className="cookies-popup__button btn btn_default"
              type="button"
              onClick={setAllCookies}>
              Accept All
            </button>
            <button
              className="cookies-popup__button btn btn_link"
              type="button"
              onClick={handleToggleSettings}>
              Manage Preferences
            </button>
          </div>
        </div>
      )}
      {isOpenedSettings && (
        <div className="cookies-popup__settings">
          <div className="cookies-popup__settings-body">
            <div className="cookies-popup__settings-title">
              Cookies Preferences
            </div>
            <div className="cookies-popup__settings-text">
              When you visit websites, they may store or retrieve data in your
              browser. This storage is often necessary for the basic
              functionality of the website. The storage may be used for
              marketing, analytics, and personalisation of the site, such as
              storing your preferences. Privacy is important to us, so you
              have the option of disabling certain types of storage that may
              not be necessary for the basic functioning of the website.
              Blocking categories may impact your experience on the website.
            </div>
            <div className="cookies-popup__settings-list">
              <div className="cookies-popup__settings-item">
                <div className="cookies-popup__settings-icon active" />
                <div className="cookies-popup__settings-item-title">
                  Essential
                </div>
                <div className="cookies-popup__settings-item-text">
                  These items are required to enable basic website
                  functionality.
                </div>
              </div>
              <label
                className="cookies-popup__settings-item"
                htmlFor="checkbox-analytics">
                <input
                  id="checkbox-analytics"
                  type="checkbox"
                  className="cookies-popup__settings-checkbox"
                  checked={cookieAnalytics}
                  onChange={handleCheckboxClick}
                  ref={analyticsCheckbox}
                />
                <div className="cookies-popup__settings-icon" />
                <div className="cookies-popup__settings-item-title">
                  Analytics
                </div>
                <div className="cookies-popup__settings-item-text">
                  These items help the website operator understand how its
                  website performs, how visitors interact with the site, and
                  whether there may be technical issues. This storage type
                  usually doesn’t collect information that identifies a
                  visitor.
                </div>
              </label>
            </div>
          </div>
          <div className="cookies-popup__buttons">
            <button
              className="cookies-popup__button btn btn_default"
              type="button"
              onClick={handleSaveSettings}>
              Save Preferences
            </button>
            <button
              className="cookies-popup__button btn btn_link"
              type="button"
              onClick={handleToggleSettings}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiesPopup;
