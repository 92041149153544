import React, {
  FunctionComponent,
  memo,
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
  useMemo,
} from "react";
import { createAction, bindActionCreators } from "../../utils/actions";
import { noop } from "../../utils/helpers";
import { isClient } from "../../utils/env";
import * as storage from "../../utils/storage";
import { navigate } from "gatsby";
import { STEPS_TYPES } from "../../constants/quiz";
import { Step } from "./types";

interface Answer {
  value?: string | string[];
  name?: string | string[];
  label?: string | string[];
  completed?: boolean;
}

const url = isClient ? new URL(window.location.href) : null;
const pathArray = isClient ? window.location.pathname.substring(1).split('/') : null;
const currentQuizId = url ? url.searchParams.get('quizId') : pathArray && pathArray[0];
const dataStorageKey = currentQuizId ? `casa_quiz_data_${currentQuizId}` : `casa_quiz_data`;

interface Answers {
  [key: string]: Answer | undefined;
}

interface State {
  email?: string;
  applicationName?: string;
  companyName?: string;
  projectId?: string;
  description?: string;
  answers: Answers;
  terms?: boolean;
  quizName?: string;
}

const storedData = storage.getItem(dataStorageKey)
  ? JSON.parse(storage.getItem(dataStorageKey) ?? "")
  : {};

const initialState: State = {
  quizName: currentQuizId ? currentQuizId : "quiz-1",
  answers: {},
  ...storedData,
};

const actions = {
  setAnswers: createAction<"SET_ANSWERS", { answers: Record<string, Answer> }>(
    "SET_ANSWERS"
  ),
  clearData: createAction<"CLEAR_DATA", { answers: object }>(
    "CLEAR_DATA"
  ),
  setAnswer: createAction<"SET_ANSWER", { answerName: string; answer: Answer }>(
    "SET_ANSWER"
  ),
  setAnswerCompleted: createAction<
    "SET_ANSWER_COMPLETED",
    { answerName: string; completed: boolean }
  >("SET_ANSWER_COMPLETED"),
  setPersonalData: createAction<
    "SET_PERSONAL_DATA",
    {
      email: string,
      applicationName: string,
      companyName: string;
      projectId: string,
      description: string,
      terms: boolean,
    }
  >("SET_PERSONAL_DATA"),
} as const;

type Action = ReturnType<ValueOf<typeof actions>>;

function reducer(state: State, action: Action) {
  switch (action.type) {
    case actions.setAnswers.type: {
      const { answers } = action.payload;

      return {
        ...state,
        answers: {
          ...state.answers,
          ...answers,
        },
      };
    }

    case actions.clearData.type: {
      return {
        answers: {},
      };
    }

    case actions.setAnswer.type: {
      const { answerName, answer } = action.payload;

      return {
        ...state,
        answers: {
          ...state.answers,
          [answerName]: {
            ...state.answers[answerName],
            ...answer,
          },
        },
      };
    }

    case actions.setAnswerCompleted.type: {
      const { answerName, completed } = action.payload;

      return {
        ...state,
        answers: {
          ...state.answers,
          [answerName]: {
            ...state.answers[answerName],
            completed,
          },
        },
      };
    }

    case actions.setPersonalData.type: {
      const { email, applicationName, companyName, projectId, description, terms } = action.payload;

      return {
        ...state,
        email,
        applicationName,
        companyName,
        projectId,
        description,
        terms,
      };
    }

    default: {
      return state;
    }
  }
}

interface ContextValue extends State {
  dispatch: Dispatch<Action>;
  steps: Step[];
  quizId?: string;
  quizName?: string;
  activeStep?: string;
  applicationName?: string;
  companyName?: string;
}

const Context = createContext<ContextValue>({
  ...initialState,
  dispatch: noop,
  steps: [],
  quizId: "",
});

const QuizProvider: FunctionComponent<{
  steps: Step[];
  activeStep: string;
  quizId: string;
  children: ReactNode;
}> = memo(props => {
  const { children, steps, activeStep, quizId } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    storage.setItem(dataStorageKey, JSON.stringify(state));
  }, [state]);

  return (
    <Context.Provider
      value={{
        answers: state.answers,
        email: state.email,
        applicationName: state.applicationName,
        companyName: state.companyName,
        projectId: state.projectId,
        description: state.description,
        dispatch,
        steps,
        activeStep,
        quizId,
        quizName: state.quizName,
      }}
    >
      {children}
    </Context.Provider>
  );
});

export default QuizProvider;

type Mapper<T = Answers> = (answers: Answers) => T;

export function useAnswers<M extends Mapper>(mapper?: Mapper<ReturnType<M>>) {
  const { answers } = useContext(Context);

  return mapper ? mapper(answers) : answers;
}

export function usePersonalData() {
  const { email, applicationName, companyName, projectId, description, terms, quizName } = useContext(Context);

  return {
    email: email,
    applicationName: applicationName,
    companyName: companyName,
    projectId: projectId,
    description: description,
    terms: terms,
    quizName: quizName,
  };
}

export function useQuizActions() {
  const context = useContext(Context);

  return useMemo(() => bindActionCreators(actions, context.dispatch), [
    context.dispatch,
  ]);
}

const path = "/:quizId/:stepId";

export function useNavigateToNextStep() {
  const { steps, activeStep, quizId } = useContext(Context);

  return () => {
    const currentStepId = activeStep;
    const nextStepIndex =
      steps.findIndex(step => step.name === currentStepId) + 1;

    if (nextStepIndex === steps.length) {
      return;
    }

    const nextStep = steps[nextStepIndex];
    const nextStepId = nextStep.name;
    let nextPath = path
      .replace(":quizId", quizId)
      .replace(":stepId", nextStepId);

    const emailId = steps.find(step => step.type === STEPS_TYPES.EMAIL)?.name;

    navigate(nextPath, {
      replace: currentStepId === emailId,
      state: { fromQuiz: true },
    });
  };
}
