import React, { FunctionComponent, memo } from "react";
import QuizProvider from "../components/Quiz/Provider";
import { WindowLocation } from "@reach/router";
import FadeTransitionRouter from "../components/FadeTransitionRouter";
import { Step } from "../components/Quiz/types";
import "./layouts.scss";

export interface Props {
  children: ReactNode;
  pageContext: {
    steps: Step[];
    step: Step;
    basePath: string;
    id: string;
    order: number;
  };
  location: WindowLocation<{ disableTransition?: boolean }>;
}

const QuizLayout: FunctionComponent<Props> = memo(props => {
  const { children, pageContext, location } = props;

  return (
    <QuizProvider
      steps={pageContext.steps && pageContext.steps}
      quizId={pageContext.id}
      activeStep={pageContext.step && pageContext.step.name}
    >
      <FadeTransitionRouter location={location}>
        {children}
      </FadeTransitionRouter>
    </QuizProvider>
  );
});

export default QuizLayout;
