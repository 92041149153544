exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-letter-tsx": () => import("./../../../src/pages/letter.tsx" /* webpackChunkName: "component---src-pages-letter-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-quiz-intro-tsx": () => import("./../../../src/templates/quizIntro.tsx" /* webpackChunkName: "component---src-templates-quiz-intro-tsx" */),
  "component---src-templates-quiz-tsx": () => import("./../../../src/templates/quiz.tsx" /* webpackChunkName: "component---src-templates-quiz-tsx" */),
  "component---src-templates-terms-tsx": () => import("./../../../src/templates/terms.tsx" /* webpackChunkName: "component---src-templates-terms-tsx" */),
  "component---src-templates-thanks-tsx": () => import("./../../../src/templates/thanks.tsx" /* webpackChunkName: "component---src-templates-thanks-tsx" */)
}

