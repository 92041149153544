import { isClient } from "./env";

const fallbackStorage: Record<string, string | null> = {};

function isStorageInWindow(storageType: "sessionStorage" | "localStorage") {
  return isClient && storageType in window;
}

export function setItem(key: string, value: string, isSession?: boolean) {
  const storageType = isSession ? "sessionStorage" : "localStorage";

  if (isStorageInWindow(storageType)) {
    try {
      window[storageType].setItem(key, value);
    } catch {
      fallbackStorage[key] = value;
    }
  } else {
    fallbackStorage[key] = value;
  }
}

export function getItem(key: string, isSession?: boolean) {
  const storageType = isSession ? "sessionStorage" : "localStorage";

  if (fallbackStorage[key]) {
    return fallbackStorage[key];
  }

  if (isStorageInWindow(storageType)) {
    return window[storageType].getItem(key);
  }

  return null;
}

export function removeItem(key: string, isSession?: boolean) {
  const storageType = isSession ? "sessionStorage" : "localStorage";

  if (fallbackStorage[key]) {
    fallbackStorage[key] = null;

    return;
  }

  if (isStorageInWindow(storageType)) {
    window[storageType].removeItem(key);
  }
}
