import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { WindowLocation } from "@reach/router";
import QuizLayout, { Props as QuizProps } from "./Quiz";
import CookiesPopup from "../components/CookiesPopup";
import OGImage from "../assets/images/og.jpg";
import "./layouts.scss";

type Props = QuizProps & {
  pageContext: {
    layoutName?: string;
  };
  children: ReactNode;
  location: WindowLocation;
};

const Layout: FunctionComponent<Props> = props => {
  const { pageContext, children, location } = props;
  const quizProps = props as QuizProps;

  return (
    <>
      <Helmet>
        <title>CASA by LSG</title>
        <meta name="description" content="CASA - Fast, easy, and effective way to analyze your app’s current security posture." />
        <meta property="og:title" content="CASA by LSG" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lsg.thecasa.io" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:description" content="CASA - Fast, easy, and effective way to analyze your app’s current security posture." />
        <meta property="og:site_name" content="CASA" />
      </Helmet>
      <TransitionGroup className="transitionGroup">
        <CSSTransition
          key={pageContext.layoutName}
          classNames={{
            enter: "fadeIn_enter",
            enterActive: "fadeIn_enterActive",
            exit: "fadeIn_exit",
          }}
          timeout={200}
        >
          <QuizLayout
            pageContext={quizProps.pageContext}
            location={quizProps.location}
          >
            {children}
          </QuizLayout>
        </CSSTransition>
      </TransitionGroup>
      <CookiesPopup />
    </>
  );
};

export default Layout;
