import { Dispatch } from "react";

export interface Action<T = any> {
  type: T;
}

export interface AnyAction extends Action {
  [extraProps: string]: any;
}

export interface ActionCreator<A> {
  (...args: any[]): A;
}

export interface ActionCreatorsMapObject<A = any> {
  [key: string]: ActionCreator<A>;
}

function bindActionCreator<A extends AnyAction = AnyAction>(
  actionCreator: ActionCreator<A>,
  dispatch: Dispatch<A>
) {
  return function boundedActionCreator(this: any, ...args: any[]) {
    return dispatch(actionCreator.apply(this, args));
  };
}

export function bindActionCreators<A, C extends ActionCreator<A>>(
  actionCreator: C,
  dispatch: Dispatch<A>
): C;

export function bindActionCreators<
  A extends ActionCreator<any>,
  B extends ActionCreator<any>
>(actionCreator: A, dispatch: Dispatch<A>): B;

export function bindActionCreators<A, M extends ActionCreatorsMapObject<A>>(
  actionCreators: M,
  dispatch: Dispatch<A>
): M;
export function bindActionCreators<
  M extends ActionCreatorsMapObject,
  N extends ActionCreatorsMapObject
>(actionCreators: M, dispatch: Dispatch<M[keyof M]>): N;

export function bindActionCreators(
  actionCreators: ActionCreator<any> | ActionCreatorsMapObject,
  dispatch: Dispatch<ActionCreator<any> | ActionCreatorsMapObject>
) {
  if (typeof actionCreators === "function") {
    return bindActionCreator(actionCreators, dispatch);
  }

  if (typeof actionCreators !== "object" || actionCreators === null) {
    throw new Error(
      `bindActionCreators expected an object or a function, instead received ${
        actionCreators === null ? "null" : typeof actionCreators
      }. ` +
        `Did you write "import ActionCreators from" instead of "import * as ActionCreators from"?`
    );
  }

  const boundActionCreators: ActionCreatorsMapObject = {};
  /* eslint-disable no-restricted-syntax */
  /* eslint-disable guard-for-in */
  for (const key in actionCreators) {
    /* eslint-enable */
    const actionCreator = actionCreators[key];
    if (typeof actionCreator === "function") {
      boundActionCreators[key] = bindActionCreator(actionCreator, dispatch);
    }
  }
  return boundActionCreators;
}

export function createAction<T = string, P = undefined, E = undefined>(
  actionType: T
) {
  const action = (payload: P, error?: E) => ({
    type: actionType,
    payload,
    error,
  });

  action.type = actionType;

  return action;
}
